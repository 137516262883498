<template>
  <fki-container
    fixed

    left="0"
    width="100%"
    column
    class="fki-page-careers"
  >
    <fki-container-header
      min-height="100px"
      :color="page.color"
      size="30px"
      :title="page.body"
    />
    <div
      v-if="jobs.length"
      class="fki-career-wrapper"
      :class="page.color"
    >
      <ul>
        <li
          v-for="job, index in jobs"
          :key="index"
          @click="selectJob(job)"
        >
          <!-- <a
            class="full-block"
            :class="page.color"
            @click="selectJob(job)"
          >&nbsp;</a> -->
          <h3
            class="fki-job-title"
            :class="page.color"
          >
            {{ job.title }}
          </h3> <p
            class="fki-job-posted"
          >
            Posted {{ formatDate(job.postedOnFormatted) }}
          </p>
          <p class="fki-job-details">
            <span
              v-if="job.department"
              class=" "
            >{{ job.department }} | </span> <span class="fki-job-location">{{ job.location }}</span> <span
              v-if="job.jobId"
              class=""
            > | </span> <span class="fki-job-id "> Job ID: {{ job.jobId }}</span>
          </p>
        </li>
      </ul>
    </div>
    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
</template>

<script>
// @ is an alias to /src
import Api from '@/services/api'
import dayjs from 'dayjs'
import { FKIFooterNav } from '@/components/FKINav'
import { FKIContainer, FKIContainerHeader } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'

export default {
  name: 'Careers',
  components: {
    'fki-container': FKIContainer,
    'fki-container-header': FKIContainerHeader,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      page: { color: 'purple', body: 'Careers', slides: [] },
      currentJob: null,
      jobs: []
    }
  },
  async created () {
    await this.getJobs()
  },
  methods: {
    selectJob (job) {
      this.currentJob = job
      this.$router
        .push({
          name: 'Job',
          params: { jobId: job.jobId }
        })
    },
    async getJobs () {
      this.isLoading = true

      try {
        this.$emit('isLoading', true)

        const { data: result } = await Api().get('/jobs')
        this.jobs = result
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
        this.$emit('isLoading', false)
      }
    },
    formatDate (date) {
      return dayjs(date).format('MMMM DD, YYYY')
    }
  }

}
</script>

<style lang="scss" scoped>
  .fki-page-careers{
    top:70px;
    bottom:0;
  }
.fki-container-header {
   border-bottom:1px solid rgba(255, 255, 255, 0.12);
}
.fki-career-wrapper {
    display: flex;
    // position: relative;
    float: left;
    width: 95%;
    height: 100%;
    min-width: 375px;
    margin: 0 auto;
    // top:70px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    align-items: flex-start;
    min-height: 1em;
    justify-content: flex-start;
    flex-direction: column;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width:100%;
      padding: 0 20px;
      li {
        position: relative;
        color: #000;
        float: left;
        clear: both;
        width: 100%;
        text-align: left;
        display: block;
        padding: 25px;
        margin: 20px 0;
        background-color: rgba(186, 186, 186);
        font-weight: bold;
        cursor: pointer;
        transition: background-color .55s ease;
        &:hover {
           background-color: rgb(215, 213, 213);
             transition: background-color .55s ease;
        }
        a {
          text-decoration: none;
        }
        .full-block {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 10000;
          cursor: pointer;
        }
        .fki-job-title {
          font-size: 16px;
          margin: 0;
        }
        .fki-job-posted {
          position: absolute;
          font-size: 13px;
          display: block;
          top: 25px;
          right: 25px;
          margin-top: 0;
        }
        .fki-job-details {
          font-size: 13px;
          line-height: 21px;
        }
      }
    }
}

@media screen and (min-width: 768px) {
  .fki-page-careers{
    top:131px;
    bottom:0;
  }
  .fki-career-wrapper {
      width: 55%;
  }
}
</style>
